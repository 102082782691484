import { ref } from "vue";
import { useStore } from "vuex";
import { fetchWithToken } from "@/helpers/fetch";
import {
  statesAccount,
  reportsInvoice,
  paymentsHistory,
  statusAccount,
  statusPaymentsHistory,
  statusReportsInvoice,
  statusLetter,
} from "../../shared/constants/documents";
const useFile = () => {
  let file = ref(null);
  const store = useStore();
  let select = ref("cuenta");
  let isLoading = ref(false);

  const setFile = (e) => {
    file.value = e.target.files[0];
  };

  const unselectFile = () => {
    file.value = null;
  };

  const validateFile = (file) => {
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return false;
    }
    return true;
  };

  const uploadFile = async (status) => {
    isLoading.value = true;
    let method =
      status === statesAccount
        ? statusAccount
        : status === reportsInvoice
        ? statusReportsInvoice
        : status === paymentsHistory
        ? statusPaymentsHistory
        : statusLetter;
    let company = store.getters["user/getCompany"];
    company = company.slice(0, 1).toUpperCase() + company.slice(1);

    const formData = new FormData();
    formData.append("file", file.value);
    formData.append("company", company);

    const response = await fetchWithToken(
      "/import/" + method,
      formData,
      "POST",
      true
    );
    if (response.status === 200) return response.json();
    else return { status: "error" };
  };

  return {
    file,
    select,
    setFile,
    uploadFile,
    unselectFile,
    validateFile,
    isLoading,
  };
};

export default useFile;
