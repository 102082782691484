<template>
  <div class="modal">
    <div class="modal-content w-60">
      <div class="icon">
        <i
          class="fas fa-times text-primary close-modal"
          @click="closedModal"
        ></i>
      </div>
      <div class="header header-images">
        <div class="featured">
          <img
            class="w-auto"
            src="/images/decorations/email-error.png"
            alt="Mensaje de error"
            title="Mensaje de error"
          />
        </div>
      </div>
      <div class="body text-center mx-auto p-0">
        <div class="form-content p-0">
          <h1 class="mb-1 mt-1 font_helvetica_neue75_bold">¡Oh no!</h1>
          <p class="mb-1 mt-1 font_helvetica_neue55_roman">
            {{ message }}
          </p>
          <div class="buttons gap-1">
            <button class="btn-secondary" @click="goRegisterPage">
              Registrarse
            </button>
            <button class="btn-gold" @click="closedModal">
              Volver a intentar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    closedModal: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const router = useRouter();

    const goRegisterPage = () => {
      router.push({
        name: 'register',
      });
    };

    return {
      goRegisterPage,
    };
  },
};
</script>
