<template>
  <div
    class="scroll mb-3"
    v-bind:class="[
      { 'account-process-show': showInfo && className === select },
      className,
    ]"
  >
    <div class="account-process-header text-end m-1" v-if="type === 'accounts'">
      <img
        @click="close"
        class="pointer"
        src="/images/decorations/back.png"
        alt="back"
        title="back"
      />
      <i
        @click="close"
        class="fas fa-times close-process pointer text-primary"
      ></i>
      <p class="font_helvetica_neue75_bold text-left">Regresar a Cuentas Corrientes</p>
    </div>
    <div
      class="account-info-header text-end m-1"
      v-if="type === 'billing'"
      @click="close"
    >
      <div class="close-info-status pointer d-flex status align-items-center">
        <img
          class="w-auto pointer mx-1"
          src="/images/decorations/back.png"
          alt="back"
          title="back"
        />
        <p class="font_helvetica_neue75_bold text-back state">
          Regresar a Facturas del mes
        </p>
      </div>
    </div>
     <div
      class="account-info-header text-end m-1"
      v-if="type === 'digital-letters'"
      @click="close"
    >
      <div class="close-info-status pointer d-flex status align-items-center">
        <img
          class="w-auto pointer mx-1"
          src="/images/decorations/back.png"
          alt="back"
          title="back"
        />
        <p class="font_helvetica_neue75_bold text-back state">
          Regresar a Letras digitales
        </p>
      </div>
    </div>
    <div
      class="account-info-header text-end m-1"
      v-if="type === 'states-account'"
      @click="close"
    >
      <div class="close-info-status pointer d-flex status align-items-center">
        <img
          class="w-auto pointer mx-1"
          src="/images/decorations/back.png"
          alt="back"
          title="back"
        />
        <p class="font_helvetica_neue75_bold text-back state">
          Regresar a Estados de cuenta
        </p>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    showInfo: {
      type: Boolean,
      required: true,
    },
    className: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    select: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'accounts',
    },
  },
};
</script>
