import {
  invoice,
  debitNote,
  creditNote,
  creditNote_,
  debitNote_,
  ticket,
} from "../constants/documents";
const getDocumentNumber = (documentNumber, typeDocument) => {
  if (typeDocument === invoice) {
    if (documentNumber.split("-").length > 2) {
      return documentNumber.substr(3);
    }
    return documentNumber;
  }
  if (typeDocument === debitNote) return documentNumber;
  if (typeDocument === creditNote_) return documentNumber.substr(3);
  if (typeDocument === ticket) return documentNumber.substr(3);
  if (typeDocument === debitNote_) return documentNumber.substr(3);
  if(typeDocument   === creditNote) return "FC01-" + documentNumber
};

export default getDocumentNumber;
